<template lang="pug">
  div.cd-sidebar-item(
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    :class="{hovered: hovered}"
  )
    .course-date
      span.course-date__code {{ cityCode }}
      v-tooltip(right content-class="cd-sidebar__tooltip")
        template(v-slot:activator='{ on, attrs }')
          span.course-date__title(v-bind='attrs' v-on='on') {{ cityName }} {{ courseName }}
        span {{ cityName }} {{ courseName }}
    .course-counts(:class="{hovered: hovered}")
      span.not_paid
        span(
          :class="{'attention': item.candidates_course_not_paid_count !== 0}"
        ) {{ item.candidates_course_not_paid_count }}
        | /{{ item.will_come_candidates_count }}
      span.label |
      span.will_not_come {{ item.will_not_come_candidates_count }}
    slot
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: 'CourseDaySidebarItem',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    hovered: false
  }),

  computed: {
    ...mapGetters({
      cities: 'crmCourseCities/items'
    }),

    courseName() {
      if (this.item.name) return '- ' + this.item.name
      else return ''
    },

    cityName() {
      if (!this.cities) return ''
      let city = this.cities.find(city => city.ID === this.item.city)
      return city.name
    },

    cityCode() {
      if (!this.cities) return ''
      let city = this.cities.find(city => city.ID === this.item.city)
      return city.code
    }
  }
}
</script>

<style lang="scss">
.cd-sidebar-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px;
  min-height: 30px;

  .course-date {
    display: flex;
    align-items: center;
    font-family: $font-roboto;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #262c33;

    &__code {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 900;
      border-radius: 4px;
      background-color: #f4f7f9;
      width: 24px;
      min-width: 24px;
      height: 24px;
    }

    &__title {
      padding-left: 5px;
      font-size: 13px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 150px;

      @include media("<=tablet") {
        max-width: initial;
      }
      @include media("<=500px") {
        max-width: 150px;
      }
    }
  }

  .course-counts {
    opacity: 1;
    pointer-events: all;
    transition: all 0.15s ease-in-out;
    margin-left: auto;

    &.hovered {
      opacity: 0;
      pointer-events: none;
      transition: all 0.15s ease-in-out;
    }

    .not_paid {
      color: #52d18b;
      margin-right: 5px;

      .attention {
        border-radius: 5px;
        margin: 2px;
        padding: 1px 4px;
        background-color: $error-color;
        color: $white;
      }
    }

    .label {
      color: $label-color;
    }

    .will_not_come {
      color: $primary-color;
      margin-left: 5px;
    }
  }
}

.cd-sidebar__tooltip {
  @include media("<=tablet") {
    display: none;
  }
}
</style>
